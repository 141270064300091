<template>
  <WrongView>
    <template #code>
      404
    </template>
  </WrongView>
</template>

<script>
// Components
import WrongView from "./WrongView";

export default {
  components: {
    WrongView
  }
};
</script>
