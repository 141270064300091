<template>
  <div>
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen text-center lg:text-left"
      >
        <div class="lg:mr-20" :class="{ '-intro-x': hasAnimation }">
          <img
            alt="Icewall Tailwind HTML Admin Template"
            class="h-48 lg:h-auto"
            :src="require(`@/assets/images/error-illustration.svg`)"
          />
        </div>

        <div class="text-white mt-10 lg:mt-0">
          <div
            class="text-8xl font-medium"
            :class="{ 'intro-x': hasAnimation }"
          >
            <slot name="code" />
          </div>

          <div
            class="text-xl lg:text-3xl font-medium mt-5"
            :class="{ 'intro-x': hasAnimation }"
          >
            {{ $t("app.not_found_title") }}
          </div>

          <div class="text-lg mt-3" :class="{ 'intro-x': hasAnimation }">
            {{ $t("app.not_found_subtitle") }}
          </div>

          <VButton
            :label="$t('app.back_to_home')"
            class="py-3 px-4 text-white border-white dark:border-dark-5 dark:text-gray-300 mt-10"
            :class="{ 'intro-x': hasAnimation }"
            @click="$router.push({ name: 'home' })"
          />
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
// Composables
import useAnimation from "@/composables/useAnimation";
// Components
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  setup() {
    // Composables
    const { hasAnimation } = useAnimation();

    // Lifecycle Hooks
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("login")
        .addClass("error-page");
    });

    return {
      // useAnimation
      hasAnimation
    };
  }
};
</script>
